import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f2664da = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _1585bc7e = () => interopDefault(import('../pages/main.vue' /* webpackChunkName: "pages/main" */))
const _2566ac9a = () => interopDefault(import('../pages/agency/list.vue' /* webpackChunkName: "pages/agency/list" */))
const _75534848 = () => interopDefault(import('../pages/agency/site.vue' /* webpackChunkName: "pages/agency/site" */))
const _65ce494e = () => interopDefault(import('../pages/auth/blockingIp.vue' /* webpackChunkName: "pages/auth/blockingIp" */))
const _437a7a92 = () => interopDefault(import('../pages/auth/connectionIp.vue' /* webpackChunkName: "pages/auth/connectionIp" */))
const _9023a4f4 = () => interopDefault(import('../pages/client/agent.vue' /* webpackChunkName: "pages/client/agent" */))
const _1f8a30ad = () => interopDefault(import('../pages/client/list.vue' /* webpackChunkName: "pages/client/list" */))
const _3ae3defb = () => interopDefault(import('../pages/game/betLog.vue' /* webpackChunkName: "pages/game/betLog" */))
const _9f059f36 = () => interopDefault(import('../pages/game/BetLogData.vue' /* webpackChunkName: "pages/game/BetLogData" */))
const _a8e8d64c = () => interopDefault(import('../pages/game/betLogSports.vue' /* webpackChunkName: "pages/game/betLogSports" */))
const _37504914 = () => interopDefault(import('../pages/game/betLogStats.vue' /* webpackChunkName: "pages/game/betLogStats" */))
const _3f7151ca = () => interopDefault(import('../pages/game/checkUser.vue' /* webpackChunkName: "pages/game/checkUser" */))
const _5f125222 = () => interopDefault(import('../pages/game/createUser.vue' /* webpackChunkName: "pages/game/createUser" */))
const _08d52346 = () => interopDefault(import('../pages/game/deposit.vue' /* webpackChunkName: "pages/game/deposit" */))
const _2dca063c = () => interopDefault(import('../pages/game/history.vue' /* webpackChunkName: "pages/game/history" */))
const _3c8bd8fa = () => interopDefault(import('../pages/game/image.vue' /* webpackChunkName: "pages/game/image" */))
const _0c0aa2f8 = () => interopDefault(import('../pages/game/providerGames.vue' /* webpackChunkName: "pages/game/providerGames" */))
const _03459dc8 = () => interopDefault(import('../pages/game/startGame.vue' /* webpackChunkName: "pages/game/startGame" */))
const _f972741a = () => interopDefault(import('../pages/game/transfer.vue' /* webpackChunkName: "pages/game/transfer" */))
const _f28acc86 = () => interopDefault(import('../pages/game/withdrawal.vue' /* webpackChunkName: "pages/game/withdrawal" */))
const _019b9314 = () => interopDefault(import('../pages/manager/activity.vue' /* webpackChunkName: "pages/manager/activity" */))
const _a6eca4d4 = () => interopDefault(import('../pages/manager/manager.vue' /* webpackChunkName: "pages/manager/manager" */))
const _0fccff75 = () => interopDefault(import('../pages/settings/category.vue' /* webpackChunkName: "pages/settings/category" */))
const _45e28a71 = () => interopDefault(import('../pages/settings/collectLog.vue' /* webpackChunkName: "pages/settings/collectLog" */))
const _f1b76f86 = () => interopDefault(import('../pages/settings/collectors.vue' /* webpackChunkName: "pages/settings/collectors" */))
const _7ca6af5d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _7f2664da,
    name: "error"
  }, {
    path: "/main",
    component: _1585bc7e,
    name: "main"
  }, {
    path: "/agency/list",
    component: _2566ac9a,
    name: "agency-list"
  }, {
    path: "/agency/site",
    component: _75534848,
    name: "agency-site"
  }, {
    path: "/auth/blockingIp",
    component: _65ce494e,
    name: "auth-blockingIp"
  }, {
    path: "/auth/connectionIp",
    component: _437a7a92,
    name: "auth-connectionIp"
  }, {
    path: "/client/agent",
    component: _9023a4f4,
    name: "client-agent"
  }, {
    path: "/client/list",
    component: _1f8a30ad,
    name: "client-list"
  }, {
    path: "/game/betLog",
    component: _3ae3defb,
    name: "game-betLog"
  }, {
    path: "/game/BetLogData",
    component: _9f059f36,
    name: "game-BetLogData"
  }, {
    path: "/game/betLogSports",
    component: _a8e8d64c,
    name: "game-betLogSports"
  }, {
    path: "/game/betLogStats",
    component: _37504914,
    name: "game-betLogStats"
  }, {
    path: "/game/checkUser",
    component: _3f7151ca,
    name: "game-checkUser"
  }, {
    path: "/game/createUser",
    component: _5f125222,
    name: "game-createUser"
  }, {
    path: "/game/deposit",
    component: _08d52346,
    name: "game-deposit"
  }, {
    path: "/game/history",
    component: _2dca063c,
    name: "game-history"
  }, {
    path: "/game/image",
    component: _3c8bd8fa,
    name: "game-image"
  }, {
    path: "/game/providerGames",
    component: _0c0aa2f8,
    name: "game-providerGames"
  }, {
    path: "/game/startGame",
    component: _03459dc8,
    name: "game-startGame"
  }, {
    path: "/game/transfer",
    component: _f972741a,
    name: "game-transfer"
  }, {
    path: "/game/withdrawal",
    component: _f28acc86,
    name: "game-withdrawal"
  }, {
    path: "/manager/activity",
    component: _019b9314,
    name: "manager-activity"
  }, {
    path: "/manager/manager",
    component: _a6eca4d4,
    name: "manager-manager"
  }, {
    path: "/settings/category",
    component: _0fccff75,
    name: "settings-category"
  }, {
    path: "/settings/collectLog",
    component: _45e28a71,
    name: "settings-collectLog"
  }, {
    path: "/settings/collectors",
    component: _f1b76f86,
    name: "settings-collectors"
  }, {
    path: "/",
    component: _7ca6af5d,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
